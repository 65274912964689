import React from 'react'
import './nav.css'
import {AiOutlineHome} from 'react-icons/ai'
import {AiOutlineUser} from 'react-icons/ai'
import {BiBook} from 'react-icons/bi'
import {RiServiceLine} from 'react-icons/ri'
import {BiMessageSquareDetail} from 'react-icons/bi'
import { useState } from 'react'
const Nav = () => {
  const [activeNew, setActiveNew] = useState('#')
  return (
   <nav>
    <a href="#" onClick={() => setActiveNew('#')} className={activeNew === '#' ? 'active' : ''}><AiOutlineHome /></a>
    <a href="#about" onClick={() => setActiveNew('#about')} className={activeNew === '#about' ? 'active' : ''}><AiOutlineUser /></a>
    <a href="#experiences" onClick={() => setActiveNew('#experiences')} className={activeNew === '#experiences' ? 'active' : ''}><BiBook /></a>
    <a href="#services" onClick={() => setActiveNew('#services')} className={activeNew === '#services' ? 'active' : ''}><RiServiceLine /></a>
    <a href="#contact" onClick={() => setActiveNew('#contact')} className={activeNew === '#contact' ? 'active' : ''}><BiMessageSquareDetail /></a>
   </nav>
  )
}

export default Nav