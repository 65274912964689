import React from "react";
import "./experience.css";
import { BsFillPatchCheckFill } from "react-icons/bs";
const Experience = () => {
  return (
    <section id="experiences">
      <h5>What Skills I Have</h5>
      <h2>My Experience</h2>
      <div className="container experience__container">
        <div className="experince__frontend">
          <h3>Fronted Developer</h3>
          <div className="experince__content">
            <article className="experiance__details">
              <BsFillPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>HTML</h4>
                <small>Experienced</small>
              </div>
            </article>
            <article className="experiance__details">
              <BsFillPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>CSS</h4>
                <small>Intermediate</small>
              </div>
            </article>
            <article className="experiance__details">
              <BsFillPatchCheckFill className="experience__details-icon"/>
              <div>
                <h4>JavaSript</h4>
                <small>Intermediate</small>
              </div>
            </article>
            <article className="experiance__details">
              <BsFillPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>Bootsrap</h4>
                <small>Experienced</small>
              </div>
            </article>
            <article className="experiance__details">
              <BsFillPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>Jquery</h4>
                <small>Intermediate</small>
              </div>
            </article>
            <article className="experiance__details">
              <BsFillPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>React</h4>
                <small>Intermediate</small>
              </div>
            </article>
          </div>
        </div>
        <div className="experince__backend">
          <h3>Backend Developer</h3>
          <div className="experince__content">
            <article className="experiance__details">
              <BsFillPatchCheckFill className="experience__details-icon"/>
              <div>
                <h4>Laravel</h4>
                <small>Intermediate</small>
              </div>
            </article>
            <article className="experiance__details">
              <BsFillPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>PHP</h4>
                <small>Experienced</small>
              </div>
            </article>
            <article className="experiance__details">
              <BsFillPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>API</h4>
                <small>Intermediate</small>
              </div>
            </article>
            <article className="experiance__details">
              <BsFillPatchCheckFill className="experience__details-icon" />
              <div>
              <h4>JSON</h4>
              <small>Intermediate</small>
              </div>
            </article>
            <article className="experiance__details">
              <BsFillPatchCheckFill className="experience__details-icon" />
              <div>
              <h4>MYSQL</h4>
              <small>Experienced</small>
              </div>
            </article>
            <article className="experiance__details">
              <BsFillPatchCheckFill className="experience__details-icon" />
<div>
<h4>Ajax</h4>
              <small>Intermediate</small>
</div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Experience;
