import React from 'react'
import './service.css'
import {BiCheck} from 'react-icons/bi'
const Service = () => {
  return (
    <section id='services'>
     <h5>What I Offer</h5>
     <h2>Services</h2>

     <div className="container service__container">
      <article className="service">
        <div className="service__head">
          <h3>UI/UX</h3>
        </div>
        <ul className="service__list">
          <li><BiCheck className='service__list-icon' />
          <p>Lorem ipsum dolor sit amet consectetur</p>
          </li>
          <li><BiCheck className='service__list-icon' />
          <p>Lorem ipsum dolor sit amet consectetur</p>
          </li>
          <li><BiCheck className='service__list-icon' />
          <p>Lorem ipsum dolor sit amet consectetur</p>
          </li>
          <li><BiCheck className='service__list-icon' />
          <p>Lorem ipsum dolor sit amet consectetur</p>
          </li>
          <li><BiCheck className='service__list-icon' />
          <p>Lorem ipsum dolor sit amet consectetur</p>
          </li>
          <li><BiCheck className='service__list-icon' />
          <p>Lorem ipsum dolor sit amet consectetur</p>
          </li>
        </ul>
      </article>
      {/* End of UI/UX */}
      <article className="service">
        <div className="service__head">
          <h3>Web Development</h3>
        </div>
        <ul className="service__list">
          <li><BiCheck className='service__list-icon' />
          <p>Lorem ipsum dolor sit amet consectetur</p>
          </li>
          <li><BiCheck className='service__list-icon' />
          <p>Lorem ipsum dolor sit amet consectetur</p>
          </li>
          <li><BiCheck className='service__list-icon' />
          <p>Lorem ipsum dolor sit amet consectetur</p>
          </li>
          <li><BiCheck className='service__list-icon' />
          <p>Lorem ipsum dolor sit amet consectetur</p>
          </li>
          <li><BiCheck className='service__list-icon' />
          <p>Lorem ipsum dolor sit amet consectetur</p>
          </li>
          <li><BiCheck className='service__list-icon' />
          <p>Lorem ipsum dolor sit amet consectetur</p>
          </li>
        </ul>
      </article>
      {/* End of Webdevelopment */}
      <article className="service">
        <div className="service__head">
          <h3>Digital Marketing</h3>
        </div>
        <ul className="service__list">
          <li><BiCheck className='service__list-icon' />
          <p>Lorem ipsum dolor sit amet consectetur</p>
          </li>
          <li><BiCheck className='service__list-icon' />
          <p>Lorem ipsum dolor sit amet consectetur</p>
          </li>
          <li><BiCheck className='service__list-icon' />
          <p>Lorem ipsum dolor sit amet consectetur</p>
          </li>
          <li><BiCheck className='service__list-icon' />
          <p>Lorem ipsum dolor sit amet consectetur</p>
          </li>
          <li><BiCheck className='service__list-icon' />
          <p>Lorem ipsum dolor sit amet consectetur</p>
          </li>
          <li><BiCheck className='service__list-icon' />
          <p>Lorem ipsum dolor sit amet consectetur</p>
          </li>
        </ul>
      </article>
      {/* End of Digital Marketing */}
     </div>
    </section>
  )
}
export default Service