import React from 'react'
import './about.css'
import ME from '../../assets/Talhazobaer.jpg'
import {FaAward} from 'react-icons/fa'
import {FiUsers} from 'react-icons/fi'
import {VscFolderLibrary} from 'react-icons/vsc'

const About = () => {
  return (
    <section id='about'>
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="" />
          </div>
        </div>
<div className="about__content">
<div className="about__cards">
  <article className='about__card'>
<FaAward className='about__icons' />
    <h5>Experience</h5>
    <small>2+ Years Working</small>
  </article>

  <article className='about__card'>
<FiUsers className='about__icons' />
    <h5>Clints</h5>
    <small>10+ Worldwide</small>
  </article>

  <article className='about__card'>
<VscFolderLibrary className='about__icons' />
    <h5>Project</h5>
    <small>20+ Complete</small>
  </article>
</div>
<p>A determined, flexible & highly organized individual, with a strong high ethic and proven 
  ability to manage people with good leadership. More over a person of strong knowdage of programing,
  Digital Marketing, good English communication backed by good computer literacy.
</p>
<a href="#contact" className='btn btn-primary'>Let's Talk</a>
</div>
</div>
    </section>
  )
}

export default About