import React from "react";
import "./contact.css";
import { MdOutlineEmail } from "react-icons/md";
import { RiMessengerLine } from "react-icons/ri";
import { BsWhatsapp } from "react-icons/bs";
import { useRef } from 'react';
import emailjs from 'emailjs-com'
const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_t7bnnw3', 'template_cdk3abd', form.current, '9lC9Od-gosHF-rSh_')
    .then((result) => {
      console.log(result.text);
  }, (error) => {
      console.log(error.text);
  });
  };
  return (
    <section id="contact">
      <h5>Get In Tuch</h5>
      <h2>Contact</h2>
      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <MdOutlineEmail className="contact__option-icons"  />
            <h4>Email</h4>
            <h5>talhazobaer.403@gmail.com</h5>
            <a href="mailto:talhazobaer.403@gmail.com" target="_blank">
              Send a Message
            </a>
          </article>
          <article className="contact__option">
            <RiMessengerLine className="contact__option-icons" />
            <h4>Messenger</h4>
            <h5>Talha Zobaer</h5>
            <a href="https://m.me/talhazobaer99" target="_blank">
              Send a Message
            </a>
          </article>
          <article className="contact__option">
            <BsWhatsapp className="contact__option-icons" />
            <h4>WhatsApp</h4>
            <h5>+88 01956314985</h5>
            <a
              href="https://api.whatsapp.com/send?phone=+8801956314985"
              target="_blank"
            >
              Send a Message
            </a>
          </article>
        </div>

        {/* END of Contact Option */}
        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name="name" placeholder="Your Name" required />
          <input type="email" name="email" placeholder="Your Email" required />
          <textarea name="message" rows="7"></textarea>
          <button
            type="submit"
            className="btn btn-primary"
            placeholder="Send Message"
          >
            Send Message
          </button>
        </form>
      </div>
    </section>
  );
};

export default Contact;
